<template>
  <div class="invoice_son_box">
    <headSearch ref="headSearch" :search-out="headSearch" />
    <div>
      <div v-for="(item,index) in list" :key="index" class="invoice_son_box_ist">
        <div class="invoice_son_box_son" @click="tolink(item)">
          <div class="list_cent">
            <span>{{ item.title }}</span>
            <span class="invoice_son_box_span">{{ item.titleType == 1 ? '公司' : '个人' }} </span>
          </div>
          <div v-if="item.titleType==1" class="list_cent">纳税人识别号：{{ item.number }}</div>
        </div>
        <div class="list_cent_xg" @click="addInvoice(item,0)">
          <img src="../../assets/images/bianji.png" alt="">
        </div>
      </div>

    </div>
    <div class="invoice_btn" @click="addInvoice(1)">新增报销凭证</div>
  </div>

</template>

<script>
import headSearch from '@/component/head/search'
import { getList } from '@/services/api'

export default {
  name: 'InvoiceSon',
  components: {
    headSearch
  },
  data() {
    return {
      list: '',
      headSearch: {
        isShowSearch: false, // 输入框 title 控制
        isShowReturn: true, // 返回 上一页 显隐
        bgColor: '#fff', // 背景颜色
        titleData: '选择报销凭证',
        callbackGo: this.callbackGo, // 回调
        callbackBtnGo: this.callbackBtnGo
      }
    }
  },
  mounted() {
    this.getListData()
  },
  methods: {
    callbackGo() {
      this.$router.go(-1)
    },
    // 返回主页
    tolink(item) {
      this.$store.commit('isTitle', item.title)
      this.$store.commit('isTitleType', item.titleType)
      this.$router.push({
        path: '/invoice'
      })
    },
    addInvoice(item, index) {
      let id = 0
      if (index === 0) {
        id = item.id // 修改
      } else {
        id = 0 // 添加
      }
      this.$store.commit('isTitleType', item.titleType)
      this.$store.commit('isNumber', item.number)
      this.$store.commit('isTitle', item.title)
      this.$store.commit('isInvoiceType', item.invoiceType)
      this.$store.commit('isId', id)

      this.$router.push({
        path: '/addInvoice',
        query: {
          id: id
        }
      })
    },
    getListData: function() {
      const data = {
        page: 1,
        pageSize: 10
      }
      getList(data).then(res => {
        if (Number(res.code) === 200) {
          this.list = res.data.list
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.invoice_son_box {
  background: #f6f6f6;
  height: 100vh;

  .invoice_btn {
    width: 100%;
    height: 44px;
    background: red;
    position: fixed;
    bottom: 0;
    text-align: center;
    line-height: 44px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
  }

  .invoice_son_box_ist {
    text-align: left;
    margin: 12px;
    height: 70px;
    background: #fff;
    padding: 12px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    position: relative;

    .invoice_son_box_son {
      min-width: 280px;
    }

    .invoice_son_box_span {
      padding: 0.05333rem 0.16rem;
      border-radius: 0.10667rem;
      font-size: 0.34667rem;
      font-style: normal;
      margin-right: 0.32rem;
      border: 0.02667rem solid #f80707;
      color: #d60f0f;
      margin-left: 12px;
    }

    .list_cent {
      flex: 1;
      padding: 4px;
      box-sizing: border-box;
    }

    .list_cent_xg {
      position: absolute;
      right: -12px;
      width: 40px;
      height: 30px;
      border-radius: 4px 0 0 4px;
      background: #fff;
      top: 50%;
      transform: translate(0, -50%);
      box-shadow: 0px 0px 30px 0px #999;

      img {
        width: 12px;
        height: auto;
        vertical-align: -8px;
        margin-left: 12px;
      }
    }
  }
}

</style>
